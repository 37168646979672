import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
} from "vue-router";
import { Modal } from "ant-design-vue";
import { store } from "../store";
import { actions } from "../utils/const";
import { hasPermission } from "../composable/usePermissions";
import { useKeycloak } from "/src/composable/useKeycloak";

export const routes = [
  {
    path: "/registration",
    name: "Registration",
    component: () =>
      import(/* webpackChunkName: "regulation" */ "../views/Registration.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      if (hasPermission(actions.REGISTRATION, store)) {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/regulation",
    name: "Regulation",
    component: () =>
      import(/* webpackChunkName: "regulation" */ "../views/Regulation.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyPolicy.vue"
      ),
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/agreements",
    name: "Agreements",
    meta: { requiresAuth: true },
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      if (store.getters.acceptedAgreements) {
        next("/");
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "agreements" */ "../views/Agreements.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    meta: { requiresAuth: true, action: actions.SHOW_PROFILE },
    component: () =>
      import(/* webpackChunkName: "regulation" */ "../views/Profile.vue"),
  },
  {
    path: "/",
    name: "Visits",
    meta: { requiresAuth: true, action: actions.SHOW_VISITS },
    component: () =>
      import(/* webpackChunkName: "visits" */ "../views/Visits.vue"),
  },
  {
    path: "/visit/:id",
    name: "Visit",
    props: (route: RouteLocationNormalized) => ({
      id: route.params.id,
      showAddDocuments: Boolean(route.params.showAddDocuments),
    }),
    meta: { requiresAuth: true, action: actions.SHOW_VISIT },
    component: () =>
      import(/* webpackChunkName: "visit" */ "../views/Visit.vue"),
  },
  {
    path: "/chat/:id",
    name: "Chat",
    props: true,
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "chat" */ "../views/Chat.vue"),
  },
  {
    path: "/nfz-visit-type",
    name: "NfzVisitType",
    meta: { requiresAuth: true },
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      store.commit("clearOrder");
      if (hasPermission(actions.SHOW_NFZ, store)) {
        next();
      } else {
        next("/make-appointment");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "nfz-visit-type" */ "../views/NfzVisitType.vue"
      ),
  },
  {
    path: "/make-appointment",
    name: "MakeAppointment",
    props: (route: RouteLocationNormalized) => {
      let data = undefined;
      let nfzChoosen = undefined;
      if (route.params.data) {
        data = JSON.parse(route.params.data.toString());
      }
      if (route.params.nfzChoosen)
        nfzChoosen = JSON.parse(route.params.nfzChoosen.toString());
      return { data, nfzChoosen };
    },
    meta: { requiresAuth: true, action: actions.SHOW_MAKE_APPOINTMENT },
    component: () =>
      import(
        /* webpackChunkName: "make-appointment" */ "../views/MakeAppointment.vue"
      ),
  },
  {
    path: "/order/:id?",
    name: "Order",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_ORDER },
    component: () => {
      if (store.state.runtimeConfig.type === "WELBI") {
        return import(
          /* webpackChunkName: "order-short" */ "../views/OrderShort.vue"
        );
      } else {
        return import(/* webpackChunkName: "order" */ "../views/Order.vue");
      }
    },
  },
  {
    path: "/order/:id/personal",
    name: "OrderPersonal",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_ORDER_PERSONAL },
    component: () =>
      import(
        /* webpackChunkName: "order-personal" */ "../views/OrderPersonal.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      if (store.state.order.id) {
        if (store.state.order.id == Number(to.params.id)) {
          next();
        } else {
          next({
            name: "Order",
            params: {
              id: to.params.id,
            },
          });
        }
      } else {
        next({
          name: "MakeAppointment",
        });
      }
    },
  },
  {
    path: "/order/:id/symptoms",
    name: "OrderSymptoms",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_ORDER_SYMPTOMS },
    component: () =>
      import(
        /* webpackChunkName: "order-symptoms" */ "../views/OrderSymptoms.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      if (store.state.order.id) {
        if (store.state.order.id == Number(to.params.id)) {
          next();
        } else {
          next({
            name: "Order",
            params: {
              id: to.params.id,
            },
          });
        }
      } else {
        next({
          name: "MakeAppointment",
        });
      }
    },
  },
  {
    path: "/order/:id/payment",
    name: "OrderPayment",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_ORDER_PAYMENT },
    component: () =>
      import(
        /* webpackChunkName: "order-payment" */ "../views/OrderPayment.vue"
      ),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      if (store.state.order.id) {
        if (store.state.order.id == Number(to.params.id)) {
          next();
        } else {
          store.commit("clearOrder");
          next({
            name: "Order",
            params: {
              id: to.params.id,
            },
          });
        }
      } else {
        next({
          name: "MakeAppointment",
        });
      }
    },
  },
  {
    path: "/appointment/:id/success",
    name: "AppointmentSuccess",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_APPOINTMENT_SUCCESS },
    component: () =>
      import(
        /* webpackChunkName: "appointment-success" */ "../views/AppointmentSuccess.vue"
      ),
  },
  {
    path: "/appointment/:id/failed",
    name: "AppointmentFailed",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_APPOINTMENT_FAILED },
    component: () =>
      import(
        /* webpackChunkName: "appointment-failed" */ "../views/AppointmentFailed.vue"
      ),
  },
  {
    path: "/appointment/:id/result",
    name: "AppointmentResult",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_APPOINTMENT_RESULT },
    component: () =>
      import(
        /* webpackChunkName: "appointment-result" */ "../views/AppointmentResult.vue"
      ),
  },
  {
    path: "/no-doctors",
    name: "NoDoctors",
    props: (route: RouteLocationNormalized) => {
      let data = undefined;
      if (route.params.data) {
        data = JSON.parse(route.params.data.toString());
      }
      return { data };
    },
    meta: { requiresAuth: true, action: actions.SHOW_NO_DOCTORS },
    component: () =>
      import(/* webpackChunkName: "no-doctors" */ "../views/NoDoctors.vue"),
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: (arg?: unknown) => void
    ) => {
      if (to.params.data) {
        next();
      } else {
        next({
          name: "MakeAppointment",
        });
      }
    },
  },
  {
    path: "/consultation/:id",
    name: "Consultation",
    props: true,
    meta: { requiresAuth: true, action: actions.SHOW_CONSULTATION },
    component: () =>
      import(
        /* webpackChunkName: "Consultation" */ "../views/Consultation.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    redirect: "/",
  },
  {
    path: "/:pathMatch(.*)",
    name: "404",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeResolve(async (to) => {
  Modal.destroyAll();
  const {
    initKeycloak,
    loginUser,
    logoutUser,
    isAuthenticated,
    kcInitialized,
  } = useKeycloak();
  if (!kcInitialized.value) {
    await initKeycloak();
  }
  const hasRoles =
    store.state.user.roles && store.state.user.roles.length > 0 ? true : false;
  const { requiresAuth, action } = to.meta;
  if (requiresAuth) {
    if (!isAuthenticated.value) {
      sessionStorage.setItem("isLoggingIn", JSON.stringify(true));
      await loginUser();
    }

    if (!hasRoles) {
      store.commit("resetUser");
      await logoutUser();
    }

    //TODO: zrobic przeszukanie po routingu
    if (hasRoles && !to.name) {
      return { path: "/" };
    }

    if (
      hasRoles &&
      !store.getters.acceptedAgreements &&
      to.name !== "Agreements"
    ) {
      return {
        name: "Agreements",
        query: {
          redirectedFrom: to.path,
        },
      };
    }

    if (hasRoles && action && !hasPermission(action as string, store)) {
      store.commit("resetUser");
      await logoutUser();
    }
  }
  store.commit("setLodingStatus", false);
});

export default router;
